import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Stack from '@mui/material/Stack';
import { PlayIcon } from 'assets/svg/PlayIcon';
import clsx from 'clsx';
import LinkComp from 'components/atoms/LinkComp';
import { MAP_BTN_TITLE_BY_REDIRECT_URL } from 'constants/data';
import { EnumProductGA } from 'constants/Enums/product';
import { DOUBLE_ARROW_ICON, SAME_INGREDIENT, SAME_STORE } from 'constants/Images';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import gtag from 'utils/gtag';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import useMobileV2 from 'zustand-lib/storeMobile';
import useStartGuide from 'zustand-lib/useStartGuide';
import { ListProductForPCWrapper } from './ListProductForPCWrapper';
import styles from './styles.module.css';
const MEGA_SALE = 'MEGA-SALE';

const ListProductWithTitle = ({
  children,
  name = "",
  redirect,
  viewMore,
  viewMoreInTitle = true,
  page = null,
  icon,
  iconWidth,
  iconHeight,
  isBestProducts = false,
  loading = false,
  type = '',
  productsType,
  isMobile,
  gap,
  forceShowButtonAtBottom = false,
  backgroundImage,
}) => {
  const { isGuide } = useStartGuide((state) => state);
  const ref = useRef(null);
  const isMobileV2 = useMobileV2((state) => state.isMobileV2());
  const TITLE_ICON = name === 'Sản phẩm cùng nhà bán hàng' ? SAME_STORE : SAME_INGREDIENT;
  const router = useRouter();
  const currentUrl = router.asPath;
  const wrapperRef = useRef(null);
  const isShowBtnAtBottom = (viewMore && productsType === MEGA_SALE) || forceShowButtonAtBottom;

  const handleGA = () => {
    if (type === EnumProductGA.SAME_MANUFACTURER) {
      gtag.clickViewAllProductSameManufacturer();
    }

    if (type === EnumProductGA.SAME_INGREDIENT) {
      gtag.clickViewAllProductSameIngredient();
    }
  };

  useEffect(() => {
    if (ref?.current) {
      if (isGuide) {
        ref.current.slickPause();
      } else {
        ref.current.slickPlay();
      }
    }
  }, [isGuide]);

  return page === 'PRD_DETAIL' ? (
    <Paper
      ref={wrapperRef}
      className={clsx(styles.wrapper, isBestProducts && styles.bestProductsWrapper, {
        [styles.wrapper_mv2]: isMobileV2,
      })}
    >
      <Box
        className={clsx(styles.icon_name, isBestProducts && styles.bestProducts, {
          [styles.icon_name_mv2]: isMobileV2,
        })}
      >
        {icon && <ImageFallbackStatic src={icon} width={iconWidth || 40} height={iconHeight || 40} alt="icon" />}
        <h5
          className={clsx(styles.name, isBestProducts && styles.bestProductsName, {
            [styles.name_mv2]: isMobileV2,
          })}
        >
          {isMobileV2 && <ImageFallbackStatic width={24} height={24} src={TITLE_ICON} />} {name}
        </h5>
        {isBestProducts && (
          <Box className={styles.bestProducts_view_more}>
            <a href={`${redirect}`}>
              <span>Xem tất cả</span>
              <ImageFallbackStatic src={DOUBLE_ARROW_ICON} width={12} height={12} alt="icon" />
            </a>
          </Box>
        )}
      </Box>
      {isBestProducts && <ListProductForPCWrapper gap={gap}>{children}</ListProductForPCWrapper>}

      {!isBestProducts && <ListProductForPCWrapper gap={gap}>{children}</ListProductForPCWrapper>}

      {viewMore && !isBestProducts && (
        <Box
          className={clsx(styles.view_more, {
            [styles.view_more_mv2]: isMobileV2,
          })}
          onClick={handleGA}
        >
          <LinkComp href={`${redirect}`} color="#0E1983" name="Xem tất cả" />
        </Box>
      )}
    </Paper>
  ) : (
    <>
      {(loading || children?.length > 0) && (
        <div
          className={clsx(isMobile ? styles.wrapper_media_mobile : styles.wrapper_media, productsType === MEGA_SALE && styles.wrapperMega)}
          ref={wrapperRef}
        >
          <div className={styles.wrapper_media_container}>
            <div className={clsx(styles.SliderProductWrap, productsType === MEGA_SALE && styles.backgroundMega)} style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: '100% 100%' }}>
              {productsType === MEGA_SALE && <div className={styles.backgroundTitle} />}
              {productsType === MEGA_SALE ? (
                <h2 className={clsx(styles.title, styles.megaSale_name)} style={{visibility: name == "" ? 'hidden' : 'visible', height: name == "" ? "74px" : "auto"}}>
                  {icon}
                  <span className={styles.contentTitleTop}>{name}</span>
                </h2>
              ) : (
                <div className={styles.wrapperTitle} style={{visibility: name == "" ? 'hidden' : 'visible'}}>
                  <h2 className={clsx(styles.title, styles.wrapperContentTitle)}>
                    {icon}
                    <span className={styles.contentTitle}>{name}</span>
                  </h2>
                  {viewMoreInTitle && (
                    <Box
                      className={styles.seeAllWrapper}
                      onClick={() => {
                        if (productsType === 'THUOC_KE_DON_GIA_TOT') {
                          gtag.clickViewAllThuocKeDonGiaTot({ currentUrl, redirect });
                        }
                      }}
                    >
                      <LinkComp href={`${redirect}`} className={clsx(isMobile ? styles.seeAllMobile : styles.seeAll)}>
                        {MAP_BTN_TITLE_BY_REDIRECT_URL[redirect] || 'Xem tất cả'}
                        <PlayIcon />
                      </LinkComp>
                    </Box>
                  )}
                </div>
              )}
              {loading ? (
                <Box className={styles.progress}>
                  <CircularProgress size={50} thickness={4} />
                </Box>
              ) : (
                <Stack>
                  <ListProductForPCWrapper gap={gap}>{children}</ListProductForPCWrapper>
                  {isShowBtnAtBottom && (
                    <Box className={styles.seeAllWrapperMega}>
                      <LinkComp href={`${redirect}`} className={clsx(isMobile ? styles.seeAllMobile : styles.seeAll)}>
                        {MAP_BTN_TITLE_BY_REDIRECT_URL[redirect] || 'Xem tất cả'}
                      </LinkComp>
                    </Box>
                  )}
                </Stack>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ListProductWithTitle;
